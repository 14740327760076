@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
h1{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 50px;
}
span {
    color: hsl(158, 45%, 60%);
}
img {
    width: 100px;
    height: 100px;
}
.container {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.left {
    padding: 80px;
    box-sizing: border-box;
}
.dnd {
    width: 152px;
    margin-top: 100px;
}
.desc {
    color: white;
    font-size: 30px;
    margin-top: 20px;
}
.right {
    display: flex;
}
.pApp1 {
    width: 250px;
    height: 450px;
} 
.pApp4 {
    width: 250px;
    height: 450px;
    transform: translate(-50px, 200px);
} 
.cpyR {
    color: white;
    font-size: 12px;
    margin-top: 10px;
    opacity: 0.5;
}
.priv {
    text-decoration: none;
    font-size: 12px;
    color: white;
}

@media only screen and (max-width: 700px) {
    body {
        overflow: hidden;
    }
    img {
        width: 50px;
        height: 50px;
    }
    h1{
        font-size: 30px;
    }
    .container {
        flex-direction: column-reverse;
    }
    .pApp1 {
        width: 150px;
        height: 250px;
    } 
    .pApp4 {
        width: 150px;
        height: 250px;
        transform: translate(-50px, 70px);
    } 
    .left {
        padding: 60px;
    }
    .dnd {
        width: 120px;
        margin-top: 50px;
    }
    .desc {
        color: white;
        font-size: 20px;
        margin-top: 10px;
    }
    .right {
        justify-content: center;
        align-items: center;
    }
  }

  @media only screen 
and (min-width : 768px) 
and (max-width : 1024px) {
    body {
        overflow: visible;
    }
    .dnd {
        width: 120px;
        margin-top: 50px;
    }
    .desc {
        color: white;
        font-size: 20px;
        margin-top: 10px;
        font-size: 20px;
    }
    .pApp1 {
        width: 200px;
        height: 350px;
    } 
    .pApp4 {
        width: 200px;
        height: 350px;
        transform: translate(-50px, 70px);
    } 
    .right {
        justify-content: center;
        align-items: center;
    }
    .left {
        padding: 40px;
    }
}